.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  height: max-content;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.react-horizontal-scrolling-menu--scroll-container.rtl {
  direction: rtl;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  overflow-y: hidden;
}

.react-horizontal-scrolling-menu--wrapper {
  display: flex;
  flex-direction: column;
}

.react-horizontal-scrolling-menu--header,
.react-horizontal-scrolling-menu--footer {
  width: 100%;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
  display: flex;
}