.custom-header {
  /* font-weight: 800 !important; */
  /* border-bottom: 1px solid #ccc; */
  background-color: #F2F2F2;
  word-break: 'break-word';
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
}

.ReactVirtualized__Table {
  width: 100%;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .ReactVirtualized__Table__headerRow {
    overflow: auto;
    width: 1000px !important;
  }

  .ReactVirtualized__Table__Grid {
    height: 500px !important
  }
}

.ReactVirtualized__Table__headerColumn {
  margin-right: 0 !important;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 0 !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 50px !important;
  height: 100% !important;
  max-height: max-content !important;
}

.ReactVirtualized__Table__row {
  width: 100% !important;
  border: 1px solid #E5E5E5;
  padding-block: 10px;
  position: relative !important;
  height: fit-content !important;
  top: 0 !important;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;

}

.ReactVirtualized__Table__rowColumn {}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0 !important;
}