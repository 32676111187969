body,
html {
  font-family: 'Roboto', sans-serif;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Set the scroll behavior to smooth */
html {
  scroll-behavior: smooth;
}

/* Set the scroll speed to a specific value */
::-webkit-scrollbar {
  /* The width of the scrollbar */

  /* The speed of the scrollbar */
  /* -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out; */
}